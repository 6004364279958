<template>
	<div>
		<div class="dF aC jSB">
			<h1 class="text-dark">
				Business Profile
			</h1>
			<a-tooltip overlayClassName="change-tooltip-color">
				<template slot="title">
					Notice that it takes 24hrs. or longer for approval of Business Profile.
				</template>
				<a-icon type="question-circle" style="font-size: 16px; color: black; margin-left: 5px;" />
			</a-tooltip>
		</div>
		<a-form-model ref="profile" :model="profile" class="profile">
			<hr style="margin-left: -24px; margin-right: -24px" />
			<h6 class="mb-2 mt-2 text-dark">Business Information</h6>
			<hr style="margin-left: -24px; margin-right: -24px" />
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Business Name" required prop="business_information.business_name"
						:rules="req('Please enter the Name of your Business')">
						<a-input v-model="profile.business_information.business_name" placeholder="Business Name"
							size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Website URL" required prop="business_information.website_url"
						:rules="req('Please enter the website URL of the business')">
						<a-input v-model="profile.business_information.website_url" placeholder="Website URL" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Business Type" required prop="business_information.business_type"
						:rules="req('Please select the type of your Business')">
						<a-select v-model="profile.business_information.business_type" placeholder="Business Type"
							size="large">
							<a-select-option v-for="(type, index) in businessTypes" :key="index" :value="type">{{
								type }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Business Industry" required prop="business_information.business_industry"
						:rules="req('Please select the your Business Industry')">
						<a-select v-model="profile.business_information.business_industry" placeholder="Business Industry"
							size="large">
							<a-select-option v-for="(industry, index) in businessIndustries" :key="index"
								:value="industry">{{ industry.replaceAll('_', ' ') }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Business Registration Identifier" required
						prop="business_information.business_registration_identifier"
						:rules="req('Please select the your Business Registration Identifier')">
						<a-select v-model="profile.business_information.business_registration_identifier"
							placeholder="Business Registration Identifier" size="large">
							<a-select-option v-for="(identifier, index) in businessRegistrationIdentifiers" :key="index"
								:value="identifier">{{
									identifier }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Business Registration Number" required
						prop="business_information.business_registration_number"
						:rules="req('Please enter the Business Registration Number')">
						<a-input v-model="profile.business_information.business_registration_number"
							placeholder="Business Registration Number" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Business Region of Operation" required
						prop="business_information.business_regions_of_operation"
						:rules="req('Please select the Business Regions of Operation')">
						<a-select v-model="profile.business_information.business_regions_of_operation"
							placeholder="Business Regions of Operation" size="large">
							<a-select-option v-for="(region, index) in businessRegions" :key="index" :value="region">{{
								region.replaceAll('_', ' ') }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Social Media Profile Urls"
						prop="business_information.social_media_profile_urls">
						<a-select v-model="profile.business_information.social_media_profile_urls" mode="tags"
							placeholder="Social Media Profile Urls" size="large"></a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<hr style="margin-left: -24px; margin-right: -24px" />
			<h6 class="mb-2 mt-2 text-dark">Business Address</h6>
			<hr style="margin-left: -24px; margin-right: -24px" />
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Name" required prop="business_address.customerName"
						:rules="req('Please enter the Name')">
						<a-input v-model="profile.business_address.customerName" placeholder="Name" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Street" required prop="business_address.street"
						:rules="req('Please enter the name of street')">
						<a-input v-model="profile.business_address.street" placeholder="Street" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="City" required prop="business_address.city"
						:rules="req('Please enter the name of city')">
						<a-input v-model="profile.business_address.city" placeholder="city" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Region" required prop="business_address.region"
						:rules="req('Please enter the name of region')">
						<a-input v-model="profile.business_address.region" placeholder="Region" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Postal Code" required prop="business_address.postalCode"
						:rules="req('Please enter the Postal Code')">
						<a-input v-model="profile.business_address.postalCode" placeholder="Postal Code" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Country" required prop="business_address.isoCountry"
						:rules="req('Please select the Country')">
						<a-select show-search size="large" class="dropdown-style"
							v-model="profile.business_address.isoCountry" optionFilterProp="label">
							<a-select-option v-for="country in countryList" :key="country.name" :value="country.value2"
								:label="country.name">
								{{ country.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<hr style="margin-left: -24px; margin-right: -24px" />
			<h6 class="mb-2 mt-2 text-dark">Authorized Business Representative</h6>
			<hr style="margin-left: -24px; margin-right: -24px" />
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="First Name" required prop="authorized_representative_one.first_name"
						:rules="req('Please enter the First Name')">
						<a-input v-model="profile.authorized_representative_one.first_name" placeholder="First Name"
							size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Last Name" required prop="authorized_representative_one.last_name"
						:rules="req('Please enter the Last Name')">
						<a-input v-model="profile.authorized_representative_one.last_name" placeholder="Last Name"
							size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Email" required prop="authorized_representative_one.email"
						:rules="req('Please enter the Email')">
						<a-input v-model="profile.authorized_representative_one.email" placeholder="Email" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Phone Number" required prop="authorized_representative_one.phone_number"
						:rules="req('Please enter the Phone Number')">
						<a-input v-model="profile.authorized_representative_one.phone_number" placeholder="Phone Number"
							size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Business Title" required prop="authorized_representative_one.business_title"
						:rules="req('Please enter the Business Title')">
						<a-input v-model="profile.authorized_representative_one.business_title" placeholder="Business Title"
							size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Job Position" required prop="authorized_representative_one.job_position"
						:rules="req('Please select the Job Position')">
						<a-select v-model="profile.authorized_representative_one.job_position" placeholder="Job Position"
							size="large">
							<a-select-option v-for="(job, index) in JobPositions" :key="index" :value="job">{{
								job.replaceAll('_', ' ') }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>

		<portal to="destination">
			<a-button v-if="profileStatus || isProfileApproved" size="large" class="text-black mx-4" type="link"
				@click="$emit('next')">SKIP</a-button>
			<a-button @click="saveProfile" size="large" type="primary" class="style-blue"
				:loading="loading">SUBMIT</a-button>
		</portal>
	</div>
</template>

<script>
import { countryList } from "bh-mod/countryList";

export default {
	props: {
		value: Number,
	},
	data: () => ({
		loading: false,
		countryList: countryList,
		profile: {
			business_information: {
				business_name: "",
				social_media_profile_urls: [],
				website_url: "",
				business_regions_of_operation: "USA_AND_CANADA",
				business_type: "Partnership",
				business_registration_identifier: "EIN",
				business_industry: "REAL_ESTATE",
				business_registration_number: ""
			},
			business_address: {
				customerName: "",
				street: "",
				city: "",
				region: "",
				postalCode: "",
				isoCountry: ""
			},
			authorized_representative_one: {
				job_position: "CEO",
				last_name: "",
				phone_number: "",
				first_name: "",
				email: "",
				business_title: ""
			}
		},
		businessTypes: ["Partnership", "Limited Liability Corporation", "Co-operative", "Non-profit Corporation", "Corporation"],
		businessIndustries: ["AUTOMOTIVE", "AGRICULTURE", "BANKING", "CONSTRUCTION", "CONSUMER", "EDUCATION", "ENGINEERING", "ENERGY", "OIL_AND_GAS", "FAST_MOVING_CONSUMER_GOODS", "FINANCIAL", "FINTECH", "FOOD_AND_BEVERAGE", "GOVERNMENT", "HEALTHCARE", "HOSPITALITY", "INSURANCE", "LEGAL", "MANUFACTURING", "MEDIA", "ONLINE", "PROFESSIONAL_SERVICES", "RAW_MATERIALS", "REAL_ESTATE", "RELIGION", "RETAIL", "JEWELRY", "TECHNOLOGY", "TELECOMMUNICATIONS", "TRANSPORTATION", "TRAVEL", "ELECTRONICS", "NOT_FOR_PROFIT"],
		businessRegistrationIdentifiers: ["EIN", "DUNS", "CBN", "CN", "ACN", "CIN", "VAT", "VATRN", "RN", "Other"],
		businessRegions: ["AFRICA", "ASIA", "EUROPE", "LATIN_AMERICA", "USA_AND_CANADA"],
		JobPositions: ["Director", "GM", "VP", "CEO", "CFO", "General Counsel", "Other"]
	}),
	computed: {
		instance() {
			return this.$store.state.instance;
		},

		profileStatus() {
			return this.instance && this.instance.twilioApplicationToPersonData && this.instance.twilioApplicationToPersonData.twilioCustomerProfile && this.instance.twilioApplicationToPersonData.twilioCustomerProfile.status
		},

		isProfileApproved() {
			return this.profileStatus === 'twilio-approved'
		}
	},
	methods: {
		req: (msg) => ({ required: true, message: msg }),

		saveProfile() {
			this.$refs.profile.validate((valid) => {
				if (valid) {
					let payload = JSON.parse(JSON.stringify(this.profile))
					payload.business_information.social_media_profile_urls = payload.business_information.social_media_profile_urls.join(', ')
					this.$api.post(`/sms-broadcasts/:instance/register-customer-profile`, payload)
						.then(() => {
							this.$message.success("You have successfully submitted profile.");
							this.$emit('next');
						})
						.catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, `An error occurred while saving your transaction as a draft. Please try again.`))
							}
						});
				} else {
					return false;
				}
			});
		},

	},
	created() { },
};
</script>

<style>
</style>
