<template>
	<div>
		<h1>Rent Phone Number</h1>

		<a-form-model ref="rentPhoneNumber" :model="rentPhoneNumber" class="profile">
			<a-form-model-item label="Select Phone Number you want to rent" required
				:rules="{ required: true, message: 'Please select a phone number you want to rent' }" prop="number">
				<a-select v-model="rentPhoneNumber.number" size="large" option-label-prop="label" showSearch>
					<a-select-option v-for="p in phoneNumbers" :key="p.phoneNumber" :value="p.phoneNumber"
						:label="p.phoneNumber">
						{{ p.phoneNumber }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
		</a-form-model>
		<a-alert type="info" message="You will be charged $10/month for renting a phone number." show-icon />

		<portal to="destination">
			<a-button size="large" class="text-black mx-4" type="link" @click="$emit('next')">SKIP</a-button>
			<a-button size="large" type="primary" class="style-blue" @click="rentNumber" :loading="loading">RENT</a-button>
		</portal>
	</div>
</template>

<script>
export default {
	props: {
		value: Number,
	},
	data: () => ({
		loading: false,
		rentPhoneNumber: {
			modal: false,
			number: null
		},
		phoneNumbers: [],
	}),
	watch: {},
	computed: {
		instance() {
			return this.$store.state.instance;
		},
		theApp() {
			return this.$store.state.theApp;
		},
		activePhoneNumbers() {
			return this.$store.state.sms.activePhoneNumbers;
		},
	},
	methods: {
		async searchPhoneNumber() {
			try {
				const { data } = await this.$api.get(`sending-phone-numbers/search`);
				this.phoneNumbers = data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching phone number list. Please try again.`))
				}
			}
		},

		rentNumber() {
			let self = this;
			this.$refs.rentPhoneNumber.validate(valid => {
				if (valid) {
					self.loading = true;
					let payload = this.phoneNumbers.find(p => p.phoneNumber == self.rentPhoneNumber.number)
					if (!payload) {
						return false;
					}
					self.$api
						.post(`sending-phone-numbers/:instance`, payload)
						.then(() => {
							self.$message.success("Successfully rented selected phone number.");
							this.$emit("next")
						})
						.catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err, `An error occurred while renting number. Please try again.`))
							}
						})
						.finally(() => {
							self.loading = false;
						});
				} else {
					return false;
				}
			});
		},
	},
	created() {
		this.searchPhoneNumber();
	},
};
</script>

<style>
</style>
